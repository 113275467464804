import React, { useState, useRef, useEffect } from 'react'

export const JourneyComponent = () => {
  const [isVisible, setIsVisible] = useState(true)
  const [questionShown, setQuestionShown] = useState(false)
  const [showFirstQuestion, setShowFirstQuestion] = useState(false)
  const promptRef = useRef(null)

  useEffect(() => {
    const handleClickOutside = event => {
      if (showFirstQuestion && promptRef.current && !promptRef.current.contains(event.target)) {
        setShowFirstQuestion(false)
      }
    }

    if (showFirstQuestion) {
      document.addEventListener('mousedown', handleClickOutside)
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [showFirstQuestion])

  if (!isVisible) return null

  return (
    <>
      <div className={`prompt-spacer`} />
      <div
        ref={promptRef}
        className={`prompt-box  ${
          showFirstQuestion ? 'show-first-question' : questionShown ? 'stow-first-question' : ''
        }`}
        onClick={() => {
          if (showFirstQuestion === false) {
            setShowFirstQuestion(!showFirstQuestion)
            setQuestionShown(true)
          }
        }}
      >
        <div
          className='close-button'
          onClick={e => {
            e.stopPropagation()
            setIsVisible(false)
          }}
        >
          ×
        </div>
        <div>
          <div
            className='chevron-vertical a-icon boschicon-bosch-ic-up spj-chevron-up'
            onClick={() => {
              if (showFirstQuestion === true) {
                setShowFirstQuestion(false)
              }
            }}
          ></div>
        </div>

        <h3>How can we help you?</h3>

        <div
          className={`questions-wrapper ${
            showFirstQuestion ? 'show-questions-wrapper' : questionShown ? 'stow-questions-wrapper' : ''
          }`}
        >
          <div className='questions-subheading'>Please select which type of product you would like help with.</div>
          <div className='e-container'>
            <div className='initial-images-grid'>
              <a href='/how-can-we-help-you/boilers' className='image image--quarter'>
                <div className='initial-icon'>
                  <img src='/img/spj/combiboiler.png' alt='Boiler' />
                </div>
                <div className='initial-text'>Boiler</div>
              </a>
              <a href='/how-can-we-help-you/heat-pumps-hybrids' className='image image--quarter'>
                <div className='initial-icon'>
                  <img src='/img/spj/Heat_pump__hybrid.png' alt='Heat Pump' />
                </div>
                <div className='initial-text'>Heat Pumps & Hybrid Systems</div>
              </a>
              <a href='/how-can-we-help-you/air-conditioning' className='image image--quarter'>
                <div className='initial-icon'>
                  <img src='/img/spj/Air_con.png' alt='Air Conditioning' />
                </div>
                <div className='initial-text'>Air Conditioning</div>
              </a>
              <a href='/how-can-we-help-you/something-else' className='image image--quarter'>
                <div className='initial-icon'>
                  <img src='/img/spj/Something_else.png' alt='Something Else' />
                </div>
                <div className='initial-text'>Something else</div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
